<template>
  <div class="content" v-loading.fullscreen.lock="loading">
    <base-header class="pb-6">
      <div class="row align-items-center py-2">
        <div class="col-lg-6 col-7"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card>
        <!-- Card header -->
        <h2 slot="header" class="mb-0" v-if="promo_code_id">Edit Promo Code</h2>
        <h2 slot="header" class="mb-0" v-else>Generate Promo Codes</h2>
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(savePromoCode)"
            enctype="multipart/form-data"
          >
            <div class="form-row">
              <div class="col-md-6">
                <label class="form-control-label"
                  >Number of Promo Codes
                  <span class="requireField">*</span></label
                >
                <base-input
                  type="number"
                  min="1"
                  name="Number of Promo code"
                  placeholder="Number of Promo Code"
                  rules="required"
                  v-model="promocode.promo_code_number"
                >
                </base-input>
              </div>
              <div class="col-md-6">
                <label class="form-control-label">Description</label>
                <textarea
                  placeholder="Promo Code Description"
                  class="form-control"
                  v-model="promocode.description"
                ></textarea>
              </div>

              <div class="col-md-6">
                <base-input label="Discount Type:">
                  <el-select
                    class="select-primary"
                    v-model="promocode.type"
                    placeholder="Discount Type"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in promoCodeTypes"
                      :key="item.name"
                      :label="item.label"
                      :value="item.name"
                    />
                  </el-select>
                </base-input>
              </div>

              <div class="col-md-6 mb-2" v-if="promocode.type == 'percentage'">
                <label class="form-control-label"
                  >Percentage (%) <span class="requireField">*</span></label
                >
                <base-input
                  type="number"
                  name="Percentage"
                  placeholder="Percentage"
                  rules="required"
                  max="100"
                  min="0"
                  v-model="promocode.percentage"
                >
                </base-input>
              </div>
              <div class="col-md-6 mb-2" v-else>
                <label class="form-control-label"
                  >Fixed Price ($) <span class="requireField">*</span></label
                >
                <base-input
                  type="number"
                  name="fixed_price"
                  placeholder="Fixed Price"
                  rules="required"
                  v-model="promocode.fixed_price"
                >
                </base-input>
              </div>

              <div class="col-md-6">
                <base-input label="Courses">
                  <el-select
                    class="select-primary"
                    v-model="promocode.course"
                    placeholder="Courses"
                    filterable
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in courses"
                      :key="item.name"
                      :label="item.label"
                      :value="item.name"
                    />
                  </el-select>
                </base-input>
              </div>

              <div class="col-md-6 mb-2">
                <label class="form-control-label"
                  >Valid Until <span class="requireField">*</span></label
                >
                <el-date-picker
                  v-model="promocode.valid_upto"
                  placeholder="Pick a date"
                  style="width: 100%"
                  format="MM/dd/yyyy"
                >
                </el-date-picker>
              </div>

              <div class="col-md-6 d-flex">
                <div>
                  <label class="form-control-label">Status</label>
                  <div class="d-flex">
                    <base-switch
                      class="mr-1"
                      type="success"
                      v-model="promocode.status"
                    ></base-switch>
                  </div>
                </div>
                <div class="ml-2">
                  <label class="form-control-label">Use once</label>
                  <div class="d-flex">
                    <base-switch
                      class="mr-1"
                      type="success"
                      v-model="promocode.use_once"
                    ></base-switch>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right mt-2">
              <base-button
                class="custom-btn"
                type="danger"
                @click="$router.go(-1)"
                >Cancel
              </base-button>
              <base-button class="custom-btn" native-type="submit"
                >{{ promo_code_id !== "" ? " Update" : "Submit" }}
              </base-button>
            </div>
          </form>
        </validation-observer>
      </card>
    </div>
  </div>
</template>
<script>
import {
  DatePicker,
  Option,
  Select,
  Table,
  TableColumn,
  TimeSelect,
} from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from "moment";
import "sweetalert2/src/sweetalert2.scss";
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      loading: false,
      promo_code_id: "",
      promocode: {
        promo_code: "",
        promo_code_number: 1,
        description: "",
        percentage: "",
        fixed_price: "",
        valid_upto: "",
        type: "percentage",
        status: true,
        course: 0,
        use_once: true,
      },
      promoCodeTypes: [
        {
          name: "percentage",
          label: "Percentage",
        },
        {
          name: "fixed_price",
          label: "Fixed Price",
        },
      ],
      courses: [
        {
          name: 0,
          label: "All",
        },
      ],
    };
  },
  created() {
    this.getCourses();
    if (this.$route.query.id) {
      this.promo_code_id = this.$route.query.id;

      this.$http
        .get("promocode/edit_promo_code/" + this.promo_code_id)
        .then((resp) => {
          let data = resp.data;
          this.promocode.promo_code = data.name;
          this.promocode.description = data.description;
          this.promocode.percentage = data.percentage;
          this.promocode.type = data.discount_type;
          this.promocode.fixed_price = data.fixed_price;
          this.promocode.valid_upto = data.validity;
          this.promocode.status = data.status ? true : false;
          this.promocode.course = data.course_id;
          this.promocode.use_once = data.use_once ? true : false;
        });
    }
  },
  methods: {
    savePromoCode() {
      if (this.promocode.valid_upto != "") {
        if (this.promo_code_id !== "") {
          this.loading = true;
          this.$http
            .put("promocode/update_promo_code/" + this.promo_code_id, {
              promo_code: this.promocode.promo_code,
              description: this.promocode.description,
              percentage: this.promocode.percentage,
              valid_upto: this.promocode.valid_upto,
              type: this.promocode.type,
              fixed_price: this.promocode.fixed_price,
              status: this.promocode.status ? 1 : 0,
              course_id: this.promocode.course,
              use_once: this.promocode.use_once ? 1 : 0,
            })
            .then((resp) => {
              Swal.fire({
                title: "Success!",
                text: `Promo Code updated successfully.`,
                icon: "success",
              });
              this.$router.push("/promo_codes");
            })
            .finally(() => (this.loading = false));
        } else {
          this.loading = true;
          this.$http
            .post("promocode/save_promo_codes", {
              promo_code_number: this.promocode.promo_code_number,
              description: this.promocode.description,
              percentage: this.promocode.percentage,
              valid_upto: this.promocode.valid_upto,
              type: this.promocode.type,
              fixed_price: this.promocode.fixed_price,
              status: this.promocode.status ? 1 : 0,
              course_id: this.promocode.course,
              use_once: this.promocode.use_once ? 1 : 0,
            })
            .then((resp) => {
              const promoCodes = resp.data.data;
              console.log(promoCodes);
              // Swal.fire({
              //   title: "Success!",
              //   text: `Promo Codes generated successfully.`,
              //   icon: "success",
              // });
              Swal.fire({
                title: "Success!",
                text: `Promo Codes generated successfully.`,
                icon: "success",
                showCancelButton: true,
                confirmButtonText:
                  '<i class="fa fa-download"></i> Download Promo Codes',
                cancelButtonText: "Close",
              }).then((result) => {
                if (result.isConfirmed) {
                  // Logic to handle the promo codes download
                  // alert("download button clicked");
                  this.downloadPromoCodes(promoCodes);
                } else {
                  this.$router.push("/promo_codes");
                }
              });
              this.$router.push("/promo_codes");
            })
            .finally(() => (this.loading = false));
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: `Please fill all required feilds.`,
          icon: "error",
        });
      }
    },
    downloadPromoCodes(promoCodes) {
      // Prepare CSV header
      const csvHeader =
        [
          "Name",
          "Description",
          "Percentage",
          "Validity",
          "Status",
          "Discount Type",
          "Fixed Price",
          "Course",
          "Created At",
          "Use Once",
        ].join(",") + "\n";

      // Prepare CSV rows
      const csvRows = promoCodes
        .map((code) =>
          [
            code.name,
            code.description,
            code.percentage !== '' ? code.percentage + "%" : "",
            moment(code.validity).format("DD MMM YYYY hh:mm A"),
            code.status === 1 ? "Active" : "Inactive",
            code.discount_type === "fixed_price" ? "Fixed Price" : "Percentage",
            code.fixed_price !== '' ? "$" + code.fixed_price : "",
            code.course_name,
            moment(code.created_at).format("DD MMM YYYY hh:mm A"),
            code.use_once === 1 ? "true" : "false",
          ].join(",")
        )
        .join("\n");

      // Combine header and rows
      const csvContent = "data:text/csv;charset=utf-8," + csvHeader + csvRows;

      // Encode the CSV content and create a download link
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "promo_codes.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getCourses: function () {
      let data = {
        course_status: "Active",
      };
      this.$http.post("course/all_courses", data).then((response) => {
        response.data.courses.forEach((course) => {
          this.courses.push({
            name: course.id,
            label: course.name,
          });
        });
      });
    },
  },
};
</script>
